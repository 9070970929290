import styled from "styled-components";

export const EntitiesDetailsWrapper = styled.div`
  .tags-wrapper {
    margin-right: 8px;
    &:nth-child(1) {
      margin-left: -8px;
    }
  }
`;
