import styled from "styled-components";

export const ConsumerDetailsStyles = styled.div`
  .details {
    #subscriptions span {
      margin-top: 5px;
      word-break: break-word;
      display: inline-block;
      padding: 0.25rem 0;
      margin-right: 1rem;
      padding-bottom: 0px;
      margin-top: 0;
    }

    > .detail-item:last-child {
      flex: 0 0 70%;
    }
  }
`;
