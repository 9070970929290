const config: any = {
  env: process.env.REACT_APP_MY_ENV || "dev",

  dev: {
    gatewayURL: "https://adh-api-dev.heartblr.org",
  },
  qa: {
    gatewayURL: "https://adh-api-qa.heartblr.org",
  },
  stg: {
    gatewayURL: "https://adh-api-stg.heartblr.org",
  },
  nfr: {
    gatewayURL: "https://adh-api-nfr.heartblr.org",
  },
  preprod: {
    gatewayURL: "https://adh-api-preprod.heartblr.org",
  },
  prod: {
    gatewayURL: "https://adh-api.heart.org",
  },
  dr: {
    gatewayURL: "https://adh-api.heartblr.org",
  },
  local: {
    // gatewayURL: "http://localhost:8080/",
    gatewayURL: "https://adh-api-dev.heartblr.org",
  },
};

export default config;
