import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import DefaultLayout from "pages/_layouts/default";
import AdminLayout from "pages/_layouts/admin";
import CONSTANTS from "common/constants";
import { logger } from "common/utils/logger.utils";
import { getParamsFromURL, onUserLoaded } from "common/utils";
import { ahaSsoLogout } from "app/hooks/auth/authManager";
import { useSelector } from "react-redux";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import Breadcrumbs from "components/BreadCrumbs";

interface PrivateRouteProps extends RouteProps {
  component: any;
  roles: any;
  adminRoute?: boolean;
  hideHeaderAndFooter?: boolean;
  pageTitle: string;
}
export default function RouteWrapper({
  component: Component,
  adminRoute,
  hideHeaderAndFooter,
  pageTitle,
  roles,
  ...rest
}: PrivateRouteProps) {
  const Layout =
    adminRoute && !hideHeaderAndFooter ? AdminLayout : DefaultLayout;

  const reduxStoreRoleCode = useSelector((state: any) => {
    return state?.user.user;
  });

  const localStoreSelectedRole =
    localStorage.getItem(CONSTANTS.ROLE_SWITCH_SEL_VALUE) ??
    reduxStoreRoleCode?.roleCode;

  logger("roleSwitch Initial Value :: ", localStoreSelectedRole);

  const history = useHistory();

  const saveUrlParams = () => {
    const { pathName, params, searchParamsUrl } = getParamsFromURL();
    localStorage.setItem("TransLogAutoFillPath", pathName ?? "");
    localStorage.setItem("TransLogAutoFillParams", JSON.stringify(params));
    localStorage.setItem("RedirectUrlParams", JSON.stringify(searchParamsUrl));
  };
  const handleSignInCallback = () => {
    CONSTANTS.WINDOW.userManager
      .signinRedirectCallback()
      .catch((err: any) => logger(err));
  };
  const hasAccessRole = () => {
    if (!roles) return true;
    return (
      typeof roles !== "undefined" && roles.includes(localStoreSelectedRole)
    );
  };
  const showAccessDeniedToast = () => {
    store.dispatch(
      showToast({
        type: "danger",
        title: " ",
        message: "Access Denied, You Don’t Have Permission To Access this URL",
      })
    );
  };

  const isInvalidRoleAccess = () => {
    return (
      roles?.includes(localStoreSelectedRole) ||
      window?.location?.pathname === "/NotFound"
    );
  };
  const isNotFoundPage = () => window?.location?.pathname === "/NotFound";

  const handleLoggedOutUser = () => {
    if (localStorage.getItem("userToken")) return;

    if (isInvalidRoleAccess() || isNotFoundPage()) return;

    saveUrlParams();
    window.location.href = "/";
  };

  const handleLoggedInUser = (userObjAuth: any) => {
    onUserLoaded(userObjAuth);
    saveUrlParams();

    if (!hasAccessRole()) {
      showAccessDeniedToast();
      history.push("/dashboard");
    }
  };

  useEffect(() => {
    const checkAuthRoleAccessNRoute = async () => {
      try {
        const userObjAuth = await CONSTANTS.WINDOW.userManager.getUser();

        if (userObjAuth) {
          handleLoggedInUser(userObjAuth);
        } else if (window.location.href.includes("#id_token")) {
          handleSignInCallback();
        } else {
          handleLoggedOutUser();
        }
      } catch (error) {
        logger("Error inside catch block:", error);
        ahaSsoLogout();
      }
    };

    checkAuthRoleAccessNRoute();
  }, [pageTitle]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <div>
            <Breadcrumbs routePath={rest.path} />
            <Component {...props} />
          </div>
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  adminRoute: false,
  hideHeaderAndFooter: false,
};
